// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-downloads-about-index-en-js": () => import("./../src/pages/downloads/about/index.en.js" /* webpackChunkName: "component---src-pages-downloads-about-index-en-js" */),
  "component---src-pages-downloads-about-index-js": () => import("./../src/pages/downloads/about/index.js" /* webpackChunkName: "component---src-pages-downloads-about-index-js" */),
  "component---src-pages-downloads-exhibition-index-en-js": () => import("./../src/pages/downloads/exhibition/index.en.js" /* webpackChunkName: "component---src-pages-downloads-exhibition-index-en-js" */),
  "component---src-pages-downloads-exhibition-index-js": () => import("./../src/pages/downloads/exhibition/index.js" /* webpackChunkName: "component---src-pages-downloads-exhibition-index-js" */),
  "component---src-pages-downloads-index-app-js": () => import("./../src/pages/downloads/indexApp.js" /* webpackChunkName: "component---src-pages-downloads-index-app-js" */),
  "component---src-pages-downloads-index-en-js": () => import("./../src/pages/downloads/index.en.js" /* webpackChunkName: "component---src-pages-downloads-index-en-js" */),
  "component---src-pages-downloads-index-js": () => import("./../src/pages/downloads/index.js" /* webpackChunkName: "component---src-pages-downloads-index-js" */),
  "component---src-pages-downloads-law-index-js": () => import("./../src/pages/downloads/law/index.js" /* webpackChunkName: "component---src-pages-downloads-law-index-js" */),
  "component---src-pages-downloads-list-index-en-js": () => import("./../src/pages/downloads/list/index.en.js" /* webpackChunkName: "component---src-pages-downloads-list-index-en-js" */),
  "component---src-pages-downloads-list-index-js": () => import("./../src/pages/downloads/list/index.js" /* webpackChunkName: "component---src-pages-downloads-list-index-js" */),
  "component---src-pages-downloads-privacy-policy-index-js": () => import("./../src/pages/downloads/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-downloads-privacy-policy-index-js" */),
  "component---src-pages-downloads-terms-of-use-index-js": () => import("./../src/pages/downloads/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-downloads-terms-of-use-index-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcast-episode-1-mdx": () => import("./../src/pages/podcast/episode1.mdx" /* webpackChunkName: "component---src-pages-podcast-episode-1-mdx" */),
  "component---src-pages-podcast-episode-2-mdx": () => import("./../src/pages/podcast/episode2.mdx" /* webpackChunkName: "component---src-pages-podcast-episode-2-mdx" */),
  "component---src-pages-podcast-episode-3-mdx": () => import("./../src/pages/podcast/episode3.mdx" /* webpackChunkName: "component---src-pages-podcast-episode-3-mdx" */),
  "component---src-pages-podcast-episode-4-mdx": () => import("./../src/pages/podcast/episode4.mdx" /* webpackChunkName: "component---src-pages-podcast-episode-4-mdx" */),
  "component---src-pages-podcast-index-js": () => import("./../src/pages/podcast/index.js" /* webpackChunkName: "component---src-pages-podcast-index-js" */),
  "component---src-templates-works-post-js": () => import("./../src/templates/works-post.js" /* webpackChunkName: "component---src-templates-works-post-js" */)
}

