import "./src/styles/base.scss"
import "./src/styles/tbModal.scss"

const addScript = (url, async = true) => {
  const script = document.createElement("script")
  script.src = url
  script.async = async
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  // 後からでも大丈夫な gumroad
  addScript("https://gumroad.com/js/gumroad.js")

  // 最初に読み込みたい zoom scroller 系のプラグイン
  addScript("/assets/plugins/scroller/Animate.js", false)
  addScript("/assets/plugins/scroller/Scroller.js", false)
  addScript("/assets/plugins/scroller/render.js", false)
}
